<template>
  <div>
    <v-btn color="green" class="" icon x-large @click="dialog = true"
      ><v-icon>mdi-plus-box</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="650">
      <v-card class="pa-5">
        <v-card-title>
          <h2>Edit Item</h2>
          <v-spacer></v-spacer>
          <!-- <v-btn small color="" @click="get_coa" class="mr-2">Get COAs</v-btn> -->
          <!-- <v-btn small color="yellow" @click="set_item_for_add">Get Item</v-btn> -->
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="photo in item.item_photos"
              :key="photo.id"
              class="d-flex child-flex"
              cols="6"
            >
              <v-img
                :src="photo.item_photo_url"
                lazy-src="@/assets/jmbcircle.png"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <v-btn
                  small
                  class="ma-2 d-flex justify-end"
                  @click="delete_item_photo(photo.item_photo_url)"
                >
                  <v-icon color="red">mdi-delete</v-icon></v-btn
                >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
          <v-form ref="form">
            <div class="mt-10 d-flex mb-10">
              <input
                class="black--text"
                type="file"
                ref="photo_upload"
                name="photo_upload"
                @change="insert_image"
                accept="image/jpeg"
              />
              <v-btn
                small
                class=""
                color=""
                @click="upload_image"
                :loading="uploading"
              >
                Upload Picture</v-btn
              >
            </div>
            <v-select
              label="Account Title"
              :items="chart_of_accounts"
              item-text="account_title"
              item-value="id"
              v-model="item.chart_of_account_id"
            ></v-select>
            <v-text-field
              label="Item Name"
              v-model="item.item_name"
            ></v-text-field>
            <v-select
              :items="item_types"
              label="Item Type"
              v-model="item.item_type_id"
              item-text="item_type"
              item-value="id"
            ></v-select>
            <v-text-field
              v-if="show_other"
              v-model="item.item_type"
              label="Other type"
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-cash"
              type="number"
              label="Unit Price"
              v-model="item.unit_price"
            ></v-text-field>
            <v-textarea
              prepend-icon="mdi-pen"
              type="text"
              label="Item Description"
              v-model="item.item_description"
            ></v-textarea>
            <v-text-field
              type="number"
              prepend-icon="mdi-percent"
              label="Profitable Margin by Percentage"
              v-model="item.profitable_margin"
            >
            </v-text-field>
            <v-text-field
              prepend-icon="mdi-truck-delivery"
              type="number"
              label="Shipping Cost"
              v-model="item.shipping_cost"
            ></v-text-field>
            <v-radio-group v-model="item.is_vat" row label="Tax Option">
              <v-radio label="Non-VAT" :value="0"></v-radio>
              <v-radio label="VAT" :value="1"></v-radio>
            </v-radio-group>
            <v-text-field
              prepend-icon="mdi-cash-multiple"
              type="number"
              label="Selling Price"
              v-model="item.selling_price"
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-package-variant-closed-plus"
              type="number"
              label="Quantity"
              v-model="item.quantity"
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-package-variant-closed-plus"
              type="number"
              label="Initial Import"
              v-model="item.initial_import"
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-package"
              type=""
              label="Unit"
              v-model="item.unit"
            ></v-text-field>
            <v-select
              prepend-icon="mdi-account-hard-hat"
              :items="suppliers"
              label="Supplier"
              item-text="supplier_name"
              item-value="id"
              v-model="item.supplier_id"
            ></v-select>
            <div class="d-flex">
              <v-text-field
                label="Warranty Count"
                type="number"
                class="mr-5"
                v-model="item.warranty.warranty_count"
              ></v-text-field>
              <v-select
                v-model="item.warranty.warranty_duration"
                label="Warranty Duration"
                :items="['Day/s', 'Week/s', 'Months/s', 'Year']"
              ></v-select>
            </div>
            <div class="d-flex">
              <v-select
                v-model="item.import_batch_id"
                label="Batch Import Description"
                :items="import_batches"
                item-value="id"
                item-text="batch_description"
              ></v-select>
              <add-batch-import-modal />
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="submit_item" :loading="loading">Edit</v-btn>
          <v-btn @click="$emit('close')">Close</v-btn>

          <v-spacer></v-spacer>
          <p class="caption grey--text">addItemModal.vue</p>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>
<script>
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { mapGetters, mapActions } from "vuex";
import addBatchImportModal from "../BatchImport/addBatchImportModal.vue";
import axios from "axios";
// import axios from "axios";
export default {
  components: { addBatchImportModal },
  props: ["item"],
  data() {
    return {
      show_other: false,
      item_photos: [],
      picture: null,
      picture_url: "",
      uploading: false,
      loading: false,
      ready: false,
      dialog: true,
      form: {
        item_name: "",
        item_type_id: "",
        unit_price: "",
        selling_price: "",
        date_received: "",
        supplier_id: "",
        warranty_count: "",
        initial_import: "",
        warranty_duration: "",
        profitable_margin: "",
        shipping_cost: "",
        vat: 0,
        is_vat: false,
        unit: "PCS",
      },
    };
  },
  methods: {
    delete_item_photo(item_photo_url) {
      // filter photos
      console.log(item_photo_url);
      let x = window.confirm("Are you sure you want to remove this photo?");
      if (x) {
        let filtered = this.item.item_photos.filter((i) => {
          return i.item_photo_url != item_photo_url;
        });
        this.item.item_photos = filtered;
      }
    },
    async upload_image() {
      // upload to firestore
      if (this.picture == "")
        return alert("Please insert image before adding item");
      this.uploading = true;
      const storage = getStorage();
      const storageRef = ref(storage, "ItemPhotos/" + this.picture_url);
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, this.picture).then(() => {
        getDownloadURL(storageRef).then((downloadURL) => {
          let request = {
            item_photo_url: downloadURL,
          };
          // insert to array
          this.item.item_photos.push(request);
          this.uploading = false;
        });
      });
    },
    async upload_image_to_db(request) {
      await axios
        .post("/item_photo", request)
        .then(() => {
          console.log("Image Successfully uploaded");
        })
        .catch((err) => {
          console.log("uploading image error:" + err);
        });
    },
    insert_image(e) {
      this.picture = e.target.files[0];
      this.picture_url = URL.createObjectURL(this.picture);
    },

    ...mapActions({
      get_item_types: "item_type/get_item_types",
      get_items: "item/get_items",
      get_suppliers: "supplier/get_suppliers",
      get_import_batches: "importBatch/get_import_batches",
      edit_item: "item/edit_item",
      get_chart_of_accounts: "chart_of_account/get_chart_of_accounts",
    }),
    async delete_all_item_photos(item_id) {
      await axios.delete("/item_photo/" + item_id);
      console.log("deleted all item Photo");
    },
    submit_item() {
      this.loading = true;
      this.edit_item(this.item)
        .then((res) => {
          // e delete anai tanan images sa DB where an item ID is res.id
          // para ma empty and ma reinsert sila tanan sa item.item_photos
          this.delete_all_item_photos(res.id).then(() => {
            // after deletion, e insert an tanan images from item_photos array to db
            this.item.item_photos.forEach((p) => {
              // request
              let request = {
                item_id: res.id,
                item_photo_url: p.item_photo_url,
              };
              this.upload_image_to_db(request);
            });
          });
          alert("Succesfully edited an item");
          this.loading = false;
        })
        .catch((err) => {
          alert(err);
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      item_types: "item_type/item_types",
      suppliers: "supplier/suppliers",
      import_batches: "importBatch/import_batches",
      chart_of_accounts: "chart_of_account/chart_of_accounts",
      item_order_for_add: "item/item_order_for_add",
    }),
  },
  async created() {
    await this.get_item_types();
    await this.get_items();
    await this.get_suppliers();
    await this.get_import_batches();
    await this.get_chart_of_accounts();
    this.ready = true;
  },
};
</script>

<style lang="scss" scoped></style>
